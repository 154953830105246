.safety{width: 100%;height: 43px;}
.all_content{height: 100%; padding-right: 15px;}
.module{ float: left ;background-color: white;height: 416px;overflow: hidden;padding: 0}
.alltitle{ height: 54px;background-color: #21bba7;color: white;font-size: 20px;margin-left: -15px;}
.alltitle span{margin-left: 40px;line-height: 54px}
.Pay{width: 90%;overflow: hidden;margin: auto}
#jf{width:100%;height: 50px;float: left;border: 2px solid #e2e2e2;background: #f1f1f1}

#jf p {
  line-height: 50px;
}
#jf input{float: right;margin-top: 10px;margin-right: 20px}
.all_content input [type=image]{outline: none;}
.mark{color: #1fbea9}
#store_Integral img{width: 100%}
#sheet{color: darkgray;font-size: 13px;}
table th{text-align: center ;background:#f1f1f1;border: 1px solid #e4e4e4}
table tr{text-align: center}
#Drug li{height: 90px;border-bottom: 1px solid lightgrey; ;list-style: none;line-height: 90px}
#Drug li a{width: 40%;height: 100%;display: inline-block}
#Drug li img{vertical-align: middle;width: 100%;height: 100%}
#handle li{width:30px;height: 30px;float: left;}
.A1img{width: 62px;height: 100%;float: right}
.buy_box tr td{height: 30px}
.wenz{display: inline-block;width: 60%;height: 100%;overflow: hidden}
.balance {margin: 0;line-height: 10px}
.checkbox input[type=checkbox]{width: 15px;height: 15px}
.expenses{margin-top: 20px }
.expenses li{list-style: none;float: left;width: 50%;}